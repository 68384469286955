import sortAlphabeticalNoSymbol from "../utility/sortAlphabeticalNoSymbol";

function fulfillRoutes(routes) {
    return routes.map((route) => {
        if (route.hasChildren && route.children) {
            // recursively calls for children routes
            route.children = fulfillRoutes(route.children);
        }

        if (route.pathname) {
            route.href = route.pathname
        }

        return route;
    })
}

function buildNorthAmericaOfferings(productsData, featured) {

    let naProducts = productsData && productsData.length > 0 ?
    productsData.filter((product) => !!product.northAmericaOffering && product.isPopular === !!featured).map((product) => {
        return {
					id: product.key,
	        title: product.title,
          pathname:`/apis/api-explorer/${product.key}`
        }
    })
    :
    [{
        id: 'hcm-offrg-wfn',
        title: 'ADP Workforce Now®',
        pathname: '/apis/api-explorer/hcm-offrg-wfn'
    }];

    if (naProducts.length === 0) {
        return null;
    } else if (!featured) {
        naProducts = naProducts.sort(sortAlphabeticalNoSymbol);
    }

    return {
        id: `apis-na${featured ? '-featured' : ''}`,
        title: `${featured ? "Featured" : "Additional"} APIs (North America)`,
        hasChildren: true,
        children: [
        ...naProducts
        ]
    }
}

function buildInternationalOfferings(productsData, featured) {

    const intProducts = productsData && productsData.length > 0 ?
    productsData.filter((product) => !!product.internationalOffering && product.isPopular === !!featured)
    .sort(sortAlphabeticalNoSymbol).map((product) => {
        return {
            id: product.key,
            title: product.title,
            pathname:`/apis/api-explorer/${product.key}`
        }
    })
    :
    [{
        id: 'pr-offrg-adp.link.prem',
        title: 'ADP® Link',
        pathname: '/apis/api-explorer/pr-offrg-adp.link.prem'
    }];

    if (intProducts.length === 0) {
        return null;
    }

    return {
        id: `apis-int${featured ? '-featured' : ''}`,
        title: `${featured ? "Featured" : "Additional"} APIs (International)`,
        hasChildren: true,
        children: [
            ...intProducts
        ]
    }

}

function getApiSubMenu(productsData){
    let naMenu;
    let intMenu;
    let naFeaturedMenu;
    let intFeaturedMenu;

    naFeaturedMenu = buildNorthAmericaOfferings(productsData, true);
    intFeaturedMenu = buildInternationalOfferings(productsData, true);
    naMenu = buildNorthAmericaOfferings(productsData);
    intMenu = buildInternationalOfferings(productsData);

    const apiSubMenuChildren = [
        {
            id: 'api-explorer',
            title: 'API Explorer',
            hasChildren: true,
            children: [
                {
                    id: 'apis-discover',
                    title: 'Discover ADP API integrations',
                    pathname: '/apis/api-explorer'
                }
            ]
        }
    ];



    const addIfTruthy = (array, item) => {
        if (item) {
            array.push(item);
        }
    }

    addIfTruthy(apiSubMenuChildren, naFeaturedMenu)
    addIfTruthy(apiSubMenuChildren, intFeaturedMenu)
    addIfTruthy(apiSubMenuChildren, naMenu)
    addIfTruthy(apiSubMenuChildren, intMenu)

    return apiSubMenuChildren;
}

const getSolutionsSubMenu = (elasticData) => {
  const solutions =
    elasticData?.hits?.hits.map(
      ({ _id: id, _index: index, _source: source }) => ({
        id,
        title: source.title,
        pathname: `/solutions/${index}/${id}`,
        na: source.region === "North America",
      })
    ) || [];

  return [
    {
      id: "solutions-na",
      title: "Solutions for North America Products",
      hasChildren: true,
      children: solutions.filter(({ na }) => na),
    },
    {
      id: "solutions-international",
      title: "Solutions for Global Products",
      hasChildren: true,
      children: solutions.filter(({ na }) => !na),
    },
  ];
};


export default function getMenu(productsData = [], elasticData = {}) {


    const appRoutes = [
        {
            id: 'home',
            title: 'Home',
            pathname: '/'
        },
        {
            id: 'getting-started',
            title: 'Getting started',
            hasChildren: true,
            children: [
                {
                    id: 'getting-started_0',
                    title: 'API Central Clients',
                    hasChildren: true,
                    children: [
                        {
                            id: 'client-integration-overview',
                            title: 'Client integration overview',
                            pathname: '/getting-started/client-integration-overview',
                        },
                        {
                            id: 'getting-started-as-a-client',
                            title: 'Getting started as a client',
                            pathname: '/getting-started/getting-started-as-a-client/guides/api-guides/api-central-quick-start-guide',
                        }
                    ]
                },
                {
                    id: 'getting-started_1',
                    title: 'ADP Marketplace Partners',
                    hasChildren: true,
                    children: [
                        {
                            id: 'partner-integration-overview',
                            title: 'Partner integration overview',
                            pathname: '/getting-started/partner-integration-overview',
                        },
                        {
                            id: 'getting-started-as-a-partner',
                            title: 'Getting started as a partner',
                            pathname: '/getting-started/getting-started-as-a-partner/guides/adp-marketplace-integration-guides/partner-development-learning-guide',
                        }
                    ]
                },
                {
                    id: 'getting-started_2',
                    title: 'Key Concepts',
                    hasChildren: true,
                    children: [
                        {
                            id: 'articles',
                            title: 'Learn about API Basics, Usage, Security and Troubleshooting',
                            pathname: '/getting-started/key-concepts',
                        }
                    ]
                },
            ]
        },
        {
            id: 'apis',
            title: 'APIs',
            hasChildren: true,
            expanded: true,
            children: getApiSubMenu(productsData)
        },
        {
            id: 'guides',
            title: 'Guides',
            hasChildren: true,
            expanded: true,
            children: [
                {
                    id: 'quick-start-guides',
                    title: 'Quickstart Guides',
                    hasChildren: true,
                    children: [
                        {
                            id: 'api-central-guide',
                            title: 'ADP API Central for clients',
                            pathname: '/guides/quick-start-guides/adp-api-central-for-clients/api-guides/api-central-quick-start-guide'
                        },
                        {
                            id: 'partner-guide',
                            title: 'ADP Marketplace partners',
                            pathname: '/guides/quick-start-guides/adp-marketplace-partners/adp-marketplace-integration-guides/partner-development-learning-guide'
                        }
                    ]
                },
                {
                    id: 'api-guides-all',
                    title: 'API Guides',
                    hasChildren: true,
                    children: [
                        {
                            id: 'api-guides-all-child',
                            title: 'View All API Guides',
                            pathname: '/guides/api-guides',
                        }
                    ]
                },
                {
                    id: 'api-guides-na',
                    title: 'API Guides by Data Type (North America)',
                    hasChildren: true,
                    children: [
                        {
                            id: 'guides-na-benefits-usecase',
                            title: 'Benefit Management',
                            pathname: '/guides/api-guides?useCaseTags=Benefit-Management',
                        },
                        {
                            id: 'guides-na-human-resources-usecase',
                            title: 'Human Resources',
                            pathname: '/guides/api-guides?useCaseTags=Human-Resources',
                        },
                        {
                            id: 'guides-na-payroll-management-usecase',
                            title: 'Payroll Management',
                            pathname: '/guides/api-guides?useCaseTags=Payroll-Management',
                        },
                        {
                            id: 'guides-na-recruiting-usecase',
                            title: 'Recruiting',
                            pathname: '/guides/api-guides?useCaseTags=Recruiting',
                        },
                        {
                            id: 'guides-na-time-labor-management-usecase',
                            title: 'Time & Labor Management',
                            pathname: '/guides/api-guides?useCaseTags=Time-Labor-Management',
                        }
                    ]
                },
                {
                    id: 'api-guides-int',
                    title: 'API Guides by Data Type (International)',
                    hasChildren: true,
                    children: [
                        {
                            id: 'guides-int-compensation-usecase',
                            title: 'Compensation',
                            pathname: '/guides/api-guides?useCaseTags=Compensation',
                        },
                        {
                            id: 'guides-int-engagement-benefits-usecase',
                            title: 'Engagement & Benefits',
                            pathname: '/guides/api-guides?useCaseTags=Engagement+%26+Benefits',
                        },
                        {
                            id: 'guides-int-ext-hr-usecase',
                            title: 'External HR & Data Connectors',
                            pathname: '/guides/api-guides?useCaseTags=External+HR+%26+Data+Connectors',
                        },
                        {
                            id: 'guides-int-finance-usecase',
                            title: 'Finance',
                            pathname: '/guides/api-guides?useCaseTags=Finance',
                        },
                        {
                            id: 'guides-int-learning-management-usecase',
                            title: 'Learning Management',
                            pathname: '/guides/api-guides?useCaseTags=Learning+Management',
                        },
                        {
                            id: 'guides-int-performance-usecase',
                            title: 'Performance',
                            pathname: '/guides/api-guides?useCaseTags=Performance',
                        },
                        {
                            id: 'guides-int-productivity-usecase',
                            title: 'Productivity & Collaboration',
                            pathname: '/guides/api-guides?useCaseTags=Productivity+%26+Collaboration',
                        },
                        {
                            id: 'guides-int-recruting-onboarding-usecase',
                            title: 'Recruitment & Onboarding',
                            pathname: '/guides/api-guides?useCaseTags=Recruitment+%26+Onboarding',
                        },
                        {
                            id: 'guides-int-time-activity-usecase',
                            title: 'Time & Activities Management',
                            pathname: '/guides/api-guides?useCaseTags=Time+%26+Activities+Management',
                        },
                        {
                            id: 'guides-int-travel-expense-management-usecase',
                            title: 'Travel & Expense Management',
                            pathname: '/guides/api-guides?useCaseTags=Travel+%26+Expense+Management',
                        }
                    ]
                },
                {
                    id: 'adp-guides-marketplace-integration-guides',
                    title: 'ADP Marketplace Integration Guides',
                    hasChildren: true,
                    children: [
                        {
                            id: 'guides-mkpl-authentication-and-security-onboarding',
                            title: 'Authentication and Security',
                            pathname: '/guides/adp-marketplace-integration-guides?mkplIntegrationCategory=Authentication-and-Security',
                        },
                        {
                            id: 'guides-mkpl-International-Marketplace',
                            title: 'International',
                            pathname: '/guides/adp-marketplace-integration-guides?mkplIntegrationCategory=International',
                        },
                        {
                            id: 'guides-mkpl-marketing-and-sales',
                            title: 'Marketing and Sales',
                            pathname: '/guides/adp-marketplace-integration-guides?mkplIntegrationCategory=Marketing-and-Sales',
                        },
                        {
                            id: 'guides-mkpl-partner-onboarding',
                            title: 'Partner Onboarding',
                            pathname: '/guides/adp-marketplace-integration-guides?mkplIntegrationCategory=Partner-Onboarding',
                        },
                        {
                            id: 'guides-mkpl-single-sign-on',
                            title: 'Single Sign-On',
                            pathname: '/guides/adp-marketplace-integration-guides?mkplIntegrationCategory=Single-Sign-On',
                        },
                        {
                            id: 'guides-mkpl-marketplace-integration',
                            title: 'Storefront Integration',
                            pathname: '/guides/adp-marketplace-integration-guides?mkplIntegrationCategory=Storefront-Integration',
                        },
                        {
                            id: 'guides-mkpl',
                            title: 'View All ADP Marketplace Integration Guides',
                            pathname: '/guides/adp-marketplace-integration-guides',
                        },
                    ]
                }
            ]
        },
        {
            id: 'solutions',
            title: 'Solutions',
            hasChildren: true,
            expanded: true,
            children: getSolutionsSubMenu(elasticData)
        },
        {
            id: 'support',
            title: 'Support',
            pathname: '/support/help-center',

        }
    ];

    return [
        {
            appRoutes: fulfillRoutes(appRoutes),
        },
    ];
}
