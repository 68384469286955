import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useFetchArticleQuery,
  useUpdateMetricsMutation,
} from "../../../store/";
import { SdfAlert } from "@synerg/react-components";
import { getBreadcrumb } from "../../../utility/articlesUtils";
import Breadcrumb from "../../../components/Breadcrumb";
import { NotFound } from "../../NotFound";
import { GuideContent } from "../../../components/guides/GuideContent";
import PageLoading from "../../../components/shared/PageLoading";

export function ViewGuidePage() {
  const { type, guideId } = useParams();

  const { isFetching, data: guide } = useFetchArticleQuery({
    index: "guides",
    id: guideId,
  });

  const getTitle = (articleItem) => {
    return (
      articleItem?.title ||
      articleItem?._source?.title ||
      articleItem?._id ||
      "No Title"
    );
  };

  const breadcrumbPath = getBreadcrumb(type, getTitle(guide));

  const [updateMetrics, results] = useUpdateMetricsMutation();

  useEffect(() => {
    updateMetrics({ id: guideId, index: "guides" });
  }, []);

  return (
    <PageLoading loading={isFetching}>
      <div className="px-8">
        <Breadcrumb path={breadcrumbPath} />
        {results.error && (
          <SdfAlert closeable status="error">
            An error occurred when updating metrics for this document
          </SdfAlert>
        )}
        {!guide && <NotFound />}
        {guide && <GuideContent content={guide} />}
      </div>
    </PageLoading>
  );
}
