import React, { useState, useRef, useEffect } from "react";
import { SdfButton, SdfTab, SdfTabGroup } from "@synerg/react-components";
import "./style.css";
import headerImg from "../../assets/images/new-graphic-v2.png";
import IntegrationSteps from "./IntegrationSteps";
import { TIMELINE_1_ITEMS, TIMELINE_2_ITEMS } from "./utility/constants";
import { useNavigate } from "react-router-dom";
import Timeline from "../shared/Timeline";

export function UseCases({ useCaseRoles }) {
  const [selectedTab, setSelectedTab] = useState(0);
  let navigate = useNavigate();

  function onHandleSwitchUseCase(idx) {
    idx === 0 ? setSelectedTab(0) : setSelectedTab(1);
  }

  function navigateGuide() {
    selectedTab === 0
      ? navigate("/getting-started/client-integration-overview")
      : navigate("/getting-started/partner-integration-overview");
  }

  const timelineRef = useRef(null);
  const [, setTimelineWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setTimelineWidth(entry.contentRect.width);
    });

    if (timelineRef.current) {
      resizeObserver.observe(timelineRef.current);
    }

    return () => {
      if (timelineRef.current) {
        resizeObserver.unobserve(timelineRef.current);
      }
    };
  }, [timelineRef.current]);

  return (
    <div className="m-auto mt-16 items-center flex flex-col">
      <div className="w-3/5 bg-action-primary-900 rounded-lg py-8 px-12 flex flex-col lg:flex-row justify-between gap-6">
        <div className="pl-4 xl:w-1/2">
          <div className="font-black text-inverse-default xl:text-heading-06 lg:text-heading-05 text-heading-05 max-w-full mt-0">
            Looking to integrate with your company’s data? Try <br /> ADP API
            Central
          </div>
          <p className="text-inverse-default mb-4 sm:mb-6 font-medium text-heading-03 max-w-full lg:max-w-4/5">
            API Central makes it easy to access ADP APIs, tools and services,
            all in one place.
          </p>
          <SdfButton
            id="demo-component"
            action="standard"
            emphasis="primary"
            size="sm"
            variant="contained"
            onClick={() =>
              navigate("/getting-started/client-integration-overview")
            }
            className=""
          >
            Learn more about API Central
          </SdfButton>
        </div>
        <div className="flex xl:h-12 xl:w-xs sm:w-sm flex-shrink-1 pt-4 justify-center items-center">
          <img
            src={headerImg}
            alt="API Central illustration"
            className="w-11/12"
          />
        </div>
      </div>

      <div className="flex flex-col m-auto w-4/5 mt-16 items-center text-center">
        <label className="font-black text-heading-06">
          What to expect when integrating with ADP
        </label>
        <p className="p-2 text-center xl:w-3/4 custom-weight text-md leading-md">
          The integration process varies depending on whether you’re an ADP
          client looking to access your own company’s data or an ADP Marketplace
          partner selling your integration to clients on our digital storefront.
        </p>

        <div className="flex items-center justify-center h-full p-8 w-4/5">
          <SdfTabGroup className="w-md">
            {useCaseRoles.map((tab, idx) => (
              <SdfTab
                key={idx}
                active={selectedTab === idx}
                size="large"
                onClick={() => onHandleSwitchUseCase(idx)}
              >
                {tab.label}
              </SdfTab>
            ))}
          </SdfTabGroup>
        </div>

        <div className="flex flex-col items-center">
          <p className="font-bold text-heading-05 text-center m-0 p-0">
            {selectedTab === 0
              ? "Integrating as an ADP client"
              : "Integrating as an ADP Marketplace partner"}
          </p>
          <div className="w-11/12">
            {selectedTab === 0 ? (
              <Timeline
                timelineItems={TIMELINE_1_ITEMS}
                hideDetails={true}
                timelineRef={timelineRef}
                homePageTimeline={false}
                cursor={false}
              />
            ) : (
              <IntegrationSteps
                timeline2Items={TIMELINE_2_ITEMS}
                timelineRef={timelineRef}
              />
            )}
          </div>
        </div>
        <SdfButton
          onClick={() => navigateGuide()}
          emphasis="primary"
          size="large"
          className="mt-4 pt-16"
          id={selectedTab === 0 ? "getStarted1" : "getStarted2"}
        >
          {selectedTab === 0
            ? "Learn what’s available to ADP clients"
            : "Learn what’s available to ADP Marketplace partners"}
        </SdfButton>
      </div>
    </div>
  );
}