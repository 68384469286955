import {
  SdfBox,
  SdfSelectSimple,
  SdfInput,
  SdfIcon,
} from "@synerg/react-components";

import "./ApiExplorerHeader.css";

/**
 * @typedef {{
 *  id: string,
 *  eventHandler: (event) => void,
 *  value?: string,
 *  items?: Record<string, string>,
 *  label?: string,
 *  placeholder?: string,
 *  loading?: boolean,
 * }} filter
 */

/**
 * @param {filter} filter
 */
const getInputPropsFromFilter = ({
  eventHandler,
  items,
  loading,
  ...rest
}) => ({
  ...rest,
  onSdfChange: eventHandler,
  items: items && !loading && Object.values(items),
});

/**
 * @param {{ filters: Array<filter>, count?: number }} props
 */
const ApiExplorerHeader = ({ filters, count }) => {
  const domainProps = getInputPropsFromFilter(
    filters.find((filter) => filter.id === "domainFilter")
  );
  const northAmericaProps = getInputPropsFromFilter(
    filters.find((filter) => filter.id === "northAmericaFilter")
  );
  const internationalProps = getInputPropsFromFilter(
    filters.find((filter) => filter.id === "internationalFilter")
  );
  const searchProps = getInputPropsFromFilter(
    filters.find((filter) => filter.id === "apiSearch")
  );
  const sortProps = getInputPropsFromFilter(
    filters.find((filter) => filter.id === "apiSort")
  );

  return (
    <>
      <SdfBox className="p-8">
        <div className="flex flex-col lg:flex-row gap-8 m-0 text-heading-05 sm:whitespace-nowrap">
          <div className="grid flex-grow gap-4 api-explorer-header-text api-explorer-header-grid">
            <div className="my-auto">Show me</div>
            <SdfSelectSimple
              className="flex-grow max-w-60 lg:max-w-full"
              hideLabel={true}
              {...domainProps}
            />
          </div>
          <div className="grid flex-grow gap-4 api-explorer-header-grid">
            <div className="my-auto">APIs for North American products</div>
            <SdfSelectSimple hideLabel={true} {...northAmericaProps} />
            <div className="my-auto">APIs for International products</div>
            <SdfSelectSimple hideLabel={true} {...internationalProps} />
          </div>
        </div>
      </SdfBox>
      <div className="py-4 m-0 text-heading-05">{`API Category Results (${
        count || 0
      })`}</div>
      <div className="flex gap-8">
        <SdfInput className="min-w-1/2" hideLabel={true} {...searchProps}>
          <SdfIcon
            icon="action-search"
            slot="after"
            className="text-alert-info"
          />
        </SdfInput>
        <SdfSelectSimple className="ml-auto" hideLabel={true} {...sortProps} />
      </div>
    </>
  );
};

export default ApiExplorerHeader;
