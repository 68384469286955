import { Fragment, useEffect, useState } from "react";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { SfcShellTopNav } from "@synerg/react-components/dist/sfc-components";
import getMenu from "../routes/menu";
import { findBestPartialPathMatchInNestedArray, findInNestedArray } from "../utility/ObjectUtil";
import './articles/previews/GuidesPrintView/WholeGuidePrintView/style.css'
import { useFetchProductsQuery, useFetchElasticSearchQuery } from "../store";
import { SdfAlert } from "@synerg/react-components";
import { buildQueryObj } from "../utility/FetchingUtils";

const getActiveItem = (allRoutes, location) => {
	const path = location.search ? location.pathname + location.search : location.pathname;
	return findInNestedArray(allRoutes, 'pathname', path, false) ||
	findBestPartialPathMatchInNestedArray(allRoutes, 'pathname', location.pathname, true);
}

function TopNav() {
	const {data: productData, isLoading: isProductLoading, isError: isProductError } = useFetchProductsQuery();
	const { data: elasticData, isLoading: isElasticLoading, isError: isElasticError } = useFetchElasticSearchQuery(
    buildQueryObj({ index: "usecases" })
  );
	const [allRoutes, setAllRoutes] = useState([]);
	const [activeItem, setActiveItem] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams()
	const isPrintView = Boolean(searchParams.get('printView'))
	const isLoading = isProductLoading || isElasticLoading;
	const isError = isProductError || isElasticError;

	useEffect((() => {
		if (!isProductLoading && !isElasticLoading) {
			const [menu] = getMenu(productData, elasticData);
			setAllRoutes(menu.appRoutes);
		}
	}), [isProductLoading, isElasticLoading]);

	useEffect(() => {
		setActiveItem(getActiveItem(allRoutes, location));
	}, [location]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setActiveItem(getActiveItem(allRoutes, location));
		}, 300);
		return () => clearTimeout(timer);
	}, [allRoutes]);

	return (!isPrintView && allRoutes && !isLoading && allRoutes &&
	<Fragment>
		{isError && <SdfAlert closeable status="error" toast={true}>
			An error occurred when fetching all Product Integrations.
		</SdfAlert>}
	<SfcShellTopNav
		id='nav'
		slot='nav'
		left-nav-type=""
		mega-menu-layout=""
		activeItem={activeItem}
		role='navigation'
		menuProvider={allRoutes}
		onSfcNavigate={(event) => {
			event.preventDefault();
			navigate(event.detail.pathname);
	}}
	data-testid='top-nav'
	/>
	</Fragment>
	)
}

export default TopNav;