import { useEffect } from "react";
import { useSelector } from "react-redux";
import Breadcrumb from "../Breadcrumb";
import { SdfBox } from "@synerg/react-components";
import { buildQueryObj } from "../../utility/FetchingUtils";
import { ArticlesListDescriptionBox } from "./ArticlesListDescriptionBox";
import { ShouldLoginBox } from "../ShouldLoginBox";
import { ArticlesList } from "./ArticlesList";
import { Filters } from "../Filters";
import { useFetchArticlesQuery } from "../../store";
import { ArticlesListFieldsDescriptions } from "./ArticlesListFieldsDescriptions";
import { urlParamsUtil } from "../../utility/useEffect/urlParamsUtil";
import { generateFiltersAndQueryArticles } from "./generateFiltersAndQueryArticles";
import { getFiltersForUrlParams } from "./filters";
import { NoData } from "../NoData";
import { PageTitle } from "../PageTitle";
import PageLoading from "../shared/PageLoading";

function ArticlesListWithFilter({
  pageTitle,
  breadcrumbPath,
  description,
  fieldsDescriptions,
  navigatePath,
  customArticlesQuery,
  filtersDefinition,
  excludeDate
}) {
  const { filters, articlesQuery, searchParams } =
    generateFiltersAndQueryArticles({ filtersDefinition });

  const isLoggedIn = useSelector((state) => {
    return state.auth.isLoggedIn;
  });

  useEffect(() => {
    urlParamsUtil(searchParams, getFiltersForUrlParams(filters));
  }, [searchParams]);

  const articlesFetch = useFetchArticlesQuery(
    buildQueryObj({
      ...articlesQuery,
      ...customArticlesQuery,
    })
  );

  const loadedContentUi = () => {
    if (articlesFetch.isLoading) return;
    if (!articlesFetch.data?.articles?.length)
      return <NoData withBorderTop withBorderBottom />;

    return (
      <ArticlesList articlesFetch={articlesFetch} navigatePath={navigatePath}  excludeDate={excludeDate}/>
    );
  };

  return (
    <PageLoading loading={articlesFetch.isFetching}>
      <div className="px-1 md:px-12">
        <Breadcrumb path={breadcrumbPath} />
        <div className="mt-22">
          <PageTitle text={pageTitle} />
          {description ? (
            <ArticlesListDescriptionBox
              illustrationName={description.illustrationName}
              text={description.text}
            />
          ) : null}
          <section className="mb-4">
            <SdfBox>
              <Filters filters={filters} filterTags />
            </SdfBox>
          </section>

          {!isLoggedIn ? <ShouldLoginBox /> : null}

          {articlesFetch.data?.articles?.length > 0 && (
            <ArticlesListFieldsDescriptions fields={fieldsDescriptions} />
          )}

          {loadedContentUi()}
        </div>
      </div>
    </PageLoading>
  );
}

export default ArticlesListWithFilter;