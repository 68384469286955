import {articlesApi, elasticSearchApi, searchApi} from "../apis";
import {buildQueryObj, sortByOptions} from "../../utility/FetchingUtils";
import {store} from "../index";


export async function getArticlesByParams({searchQuery, guideType = "", tag, index = "", from = 0, size, ...rest}) {
    const result = await store.dispatch(articlesApi.endpoints.fetchArticles.initiate(buildQueryObj({
        query: searchQuery,
        guideType,
        tags: Array.isArray(tag) ? tag : [tag],
        index,
        from,
        size,
        type: "_doc",
        ...rest
    })))

    return {
        isError: result.isError,
        articles: result.data?.articles,
        total: result.data?.total?.value
    }
}

export async function getIntegrationBySolutionArticles({searchQuery}) {
    const result = await store.dispatch(elasticSearchApi.endpoints.fetchElasticSearch.initiate({
        query: searchQuery,
        index: "usecases",
        queryResultMaxSize: 10,
    }))

    return {
        isError: result.isError,
        articles: result.data?.hits?.hits,
        total: result.data?.hits?.total?.value
    }
}


export async function getAllArticles({searchQuery, from = 0, size = 10, sort = sortByOptions.mostRecent}) {

    const result = await store.dispatch(searchApi.endpoints.fetchSearch.initiate({
        forceRefetch: true,
        from,
        query: searchQuery,
        sort,
        isMultiIndexSearch: true,
        queryResultMaxSize: size,
    }))


    return {
        isError: result.isError,
        articles: result.data?.hits?.hits,
        total: result.data?.hits?.total?.value
    }
}