import React from 'react';
import {Route, Routes} from 'react-router-dom';

import WelcomePage from "../pages/WelcomePage";
import KeyConceptsPage from "../pages/Learn/KeyConceptsPage";
import ProductIntegrationGuidesPage from "../pages/Build/Guides/ProductIntegrationGuidesPage";
import HelpCenterPage from "../pages/Support/HelpCenterPage";
import EventNotification from "../pages/eventNotificationPage";
import ViewArticlePage from "../pages/ViewArticlePage";
import Health from "../pages/Health";
import MarketplaceIntegrationGuidesPage from "../pages/Build/Guides/MarketplaceIntegrationGuidesPage";
import ViewSearchResults from '../pages/ViewSearchResults';
import { ApiExplorerProductView, ApiExplorerApiView} from "../pages/Build/ApiExplorer";
import UseCasePage from '../pages/Build/IntegrationsBySolution/UseCasePage';
import ViewGuideChapterPage from "../pages/Build/Guides/ViewGuideChapterPage";
import {NotFound} from "../pages/NotFound";
import {CmsPreviewArticle} from "../pages/Previews/CmsPreviewArticle";
import {ViewGuidePage} from "../pages/Build/Guides/ViewGuidePage";
import {UseCasePreview} from '../pages/Previews/UseCasePreview';
import ClientIntegrationPage from '../pages/ClientIntegrationPage';
import { PartnerIntegrationPage } from '../pages/PartnerIntegrationPage';
import FeaturedApisPage from '../pages/FeaturedApisPage';
import { HowtoOrKeyConceptsArticlePath } from "../components/legacy-redirects/HowtoOrKeyConceptsArticlePath";
import { ApiExplorerProductApiPath } from "../components/legacy-redirects/ApiExplorerProductApiPath";
import { GuidesPath } from "../components/legacy-redirects/GuidesPath";
import { legacyRedirects } from "./legacyRedirects";

export default function RoutesFunction() {
    return (
        <Routes>
            {/*<Route exact path="/" element={<Navigate to="/welcome"/>}/>*/}
            {/*<Route exact path='/welcome' element={<WelcomePage/>}/>*/}
            <Route exact path="/" element={<WelcomePage/>}/>
            <Route exact path="/v1/health" element={<Health/>}/>
            <Route exact path="/health" element={<Health/>}/>


            {/* Getting Started routes */}
            <Route path='/getting-started/client-integration-overview' element={<ClientIntegrationPage/>} />
            <Route path='/getting-started/partner-integration-overview' element={<PartnerIntegrationPage/>}/>
            <Route path='/getting-started/key-concepts' element={<KeyConceptsPage/>}/>
            <Route path='/getting-started/:type/:articleId' element={<ViewArticlePage/>}/>
            <Route path='/getting-started/key-concepts/event-api-event-guide' element={<EventNotification/>}/>
            <Route path='/getting-started/getting-started-as-a-client/guides/:type/:guideId' element={<ViewGuidePage/>}/>
            <Route path='/getting-started/getting-started-as-a-client/guides/:type/:guideId/chapter/:chapterId' element={<ViewGuideChapterPage/>}/>
            <Route path='/getting-started/getting-started-as-a-partner/guides/:type/:guideId' element={<ViewGuidePage/>}/>
            <Route path='/getting-started/getting-started-as-a-partner/guides/:type/:guideId/chapter/:chapterId' element={<ViewGuideChapterPage/>}/>


                {/* API Explorer routes */}
            <Route path='/apis/api-explorer' element={<FeaturedApisPage/>}/>
            <Route path='/apis/api-explorer/:productCode' element={<ApiExplorerProductView/>}/>
            <Route path='/apis/api-explorer/:productCode/:apiCode' element={<ApiExplorerApiView/>}/>

            {/* Solutions routes */}
            <Route path='/solutions/:index/:id' element={<UseCasePage/>}/>


            {/* Learn routes */}
            {/*<Route exact path='/learn/learn-overview' element={<LearnPage/>}/>*/}
            {/*<Route path='/learn/key-concepts' element={<KeyConceptsPage/>}/>*/}
            {/*<Route path='/learn/:type/:articleId' element={<ViewArticlePage/>}/>*/}
            {/*<Route path='/learn/key-concepts/event-api-event-guide' element={<EventNotification/>}/>*/}

            {/*<Route exact path='/learn/how-to-articles' element={<HowToArticlesPage/>}/>*/}
            {/*<Route exact path='/learn/faqs' element={<FaqsPage/>}/>*/}

            {/*/!* Build routes *!/*/}

                {/* Solutions routes*/}
            {/*/!*<Route exact path='/build/overview' element={<BuildPage/>}/>*!/*/}
            {/*<Route path='/build/integrations-by-solution' element={<IntegrationsBySolutionPage/>}/>*/}
            {/*<Route path='/build/integrations-by-solution/:index/:id' element={<UseCasePage/>}/>*/}

            {/*<Route path='/build/guides/marketplace-integration-guides'*/}
            {/*           element={<MarketplaceIntegrationGuidesPage/>}/>*/}
            {/*<Route path='/build/guides/product-integration-guides'*/}
            {/*           element={<ProductIntegrationGuidesPage/>}/>*/}
            {/*<Route path='/build/guides/:type/:guideId' element={<ViewGuidePage/>}/>*/}
            {/*<Route path='/build/guides/:type/:guideId/chapter/:chapterId' element={<ViewGuideChapterPage/>}/>*/}

            <Route path='/guides/adp-marketplace-integration-guides'
                       element={<MarketplaceIntegrationGuidesPage/>}/>
            <Route path='/guides/api-guides'
                       element={<ProductIntegrationGuidesPage/>}/>
            <Route path='/guides/:type/:guideId' element={<ViewGuidePage/>}/>
            <Route path='/guides/:type/:guideId/chapter/:chapterId' element={<ViewGuideChapterPage/>}/>

           <Route path='/guides/quick-start-guides/adp-api-central-for-clients/:type/:guideId' element={<ViewGuidePage/>}/>
           <Route path='/guides/quick-start-guides/adp-api-central-for-clients/:type/:guideId/chapter/:chapterId' element={<ViewGuideChapterPage/>}/>
           <Route path='/guides/quick-start-guides/adp-marketplace-partners/:type/:guideId' element={<ViewGuidePage/>}/>
           <Route path='/guides/quick-start-guides/adp-marketplace-partners/:type/:guideId/chapter/:chapterId' element={<ViewGuideChapterPage/>}/>


                {/*/!* API Explorer routes *!/*/}
            {/*<Route path='/build/api-explorer' element={<ApiExplorerPage/>}/>*/}
            {/*<Route path='/build/api-explorer/:productCode' element={<ApiExplorerProductView/>}/>*/}
            {/*<Route path='/build/api-explorer/:productCode/:apiCode' element={<ApiExplorerApiView/>}/>*/}

            {/* Support routes */}
            {/*<Route exact path='/support/support-overview' element={<SupportPage/>}/>*/}
            <Route exact path='/support/help-center' element={<HelpCenterPage/>}/>

            {/* Search route */}
            <Route exact path='/searchresults' element={<ViewSearchResults/>}/>

            {/* Preview articles and guides route*/}
            <Route exact path='/articles/preview/:articleId' element={<CmsPreviewArticle/>}/>

            {/*Preview use case */}
            <Route exact path='/api-usecases/usecases-preview/:useCaseID' element={<UseCasePreview/>}/>

            {/* Legacy paths redirects for backwards compatibility */}
            {
                legacyRedirects({
                    '/getting-started/client-integration-overview1': '/getting-started/client-integration-overview',
                    '/learn': '/learn/learn-overview',
                    '/metadata/collections/all/learn': '/learn/learn-overview',
                    '/articles/general/all/keyconcept': '/learn/key-concepts',
                    '/articles/general/all/howto': '/learn/how-to-articles',
                    '/articles/general/:id': <HowtoOrKeyConceptsArticlePath/>,

                    '/articles/faq/all/faq': '/learn/faqs',
                    '/articles/faq/:id': '/learn/faqs/:id',

                    '/build': '/build/overview',
                    '/metadata/collections/all/build': '/build/overview',

                    '/api-usecases/usecases/': '/build/integrations-by-solution',
                    '/api-usecases/usecases/:id': '/build/integrations-by-solution/usecases/:id',

                    '/articles/api/all/apiexplorer': '/build/api-explorer',
                    '/articles/api/:id/apiexplorer': '/build/api-explorer/:id',
                    '/articles/api/:productName/:apiId/apiexplorer': <ApiExplorerProductApiPath/>,

                    '/articles/guides/all/productIntegration/productIntegration': '/build/guides/product-integration-guides',
                    '/articles/guides/all/mkplIntegration/mkplIntegration': '/build/guides/marketplace-integration-guides',
                    '/articles/guides/:id': <GuidesPath/>,

                    '/metadata/collections/all/support': '/support/support-overview',
                    '/static/helpcenter/': '/support/help-center',
                })
            }

            <Route exact path='*' element={<NotFound/>}/>
        </Routes>
    );
}