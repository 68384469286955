export const AVAILABLE_APIs = [
  [
    { fieldName: "person.legalName.givenName", exampleValue: "Anthony" },
    { fieldName: "person.legalName.middleName", exampleValue: "A" },
    { fieldName: "person.legalName.familyName1", exampleValue: "Albright" },
    { fieldName: "person.birthDate", exampleValue: "1988-10-30" },
    {
      fieldName: "person.governmentIDs.idValue (SSN)",
      exampleValue: "XXX-XX-3761",
    },
    {
      fieldName: "person.legalAddress.lineOne",
      exampleValue: "123 Main Street",
    },
    { fieldName: "person.legalAddress.lineTwo", exampleValue: "Apt 4B" },
    { fieldName: "person.legalAddress.cityName", exampleValue: "Roseland" },
    {
      fieldName: "person.legalAddress.countrySubdivisionLevel1.shortName",
      exampleValue: "New Jersey",
    },
    {
      fieldName: "communication.mobiles.formattedNumber",
      exampleValue: "202-555-5555 (cell)",
    },
  ],
  [
    { fieldName: "person.genderCode.codeValue", exampleValue: "M" },
    { fieldName: "person.genderCode.shortName", exampleValue: "Male" },
    { fieldName: "person.maritalStatusCode.codeValue", exampleValue: "W" },
    {
      fieldName: "person.maritalStatusCode.shortName",
      exampleValue: "Widowed",
    },
    {
      fieldName: "person.ethnicityCode.longName",
      exampleValue: "Not Hispanic or Latino",
    },
    { fieldName: "person.raceCode.longName", exampleValue: "White" },
    { fieldName: "person.raceCode.shortName", exampleValue: "Wh" },
    { fieldName: "person.tobaccoUserIndicator", exampleValue: "False" },
    {
      fieldName: "person.disabledIndicator",
      exampleValue: "False",
    },
    {
      fieldName: "person.SocialInsurancePrograms.nameCode.shortName",
      exampleValue: "Medicare",
    },
  ],
  [
    { fieldName: "workAssignments.hireDate", exampleValue: "01/01/2015" },
    { fieldName: "workerStatus.StatusCode.codeValue", exampleValue: "Active" },
    {
      fieldName: "workAssignments.actualStartDate",
      exampleValue: "01/08/2015",
    },
    {
      fieldName: "workAssignments.jobCode.shortName",
      exampleValue: "Part-time",
    },
    { fieldName: "workAssignments.jobCode.codeValue", exampleValue: "PT" },
    {
      fieldName: "workAssignments.jobTitle",
      exampleValue: "Billing Coordinator",
    },
    {
      fieldName: "workAssignments.homeOrganizationalUnits.typeCode.shortName",
      exampleValue: "Business Unit",
    },
    {
      fieldName: "workAssignments.homeOrganizationalUnits.nameCode.shortName",
      exampleValue: "Accounting",
    },
    {
      fieldName: "workAssignments.itemID",
      exampleValue: "00854955E84",
    },
    {
      fieldName: "workerDates.OriginalHireDate",
      exampleValue: "05/05/2010",
    },
  ],
  [
    { fieldName: "payCycleCode.shortName", exampleValue: "Weekly" },
    { fieldName: "standardPayPeriodHours", exampleValue: "40" },
    { fieldName: "payPeriodRateAmount.amountValue", exampleValue: "1233" },
    { fieldName: "payPeriodRateAmount.currencyCode", exampleValue: "USD" },
    { fieldName: "payPeriodRateAmount.nameCode", exampleValue: "Salary" },
    { fieldName: "payrollGroupCode", exampleValue: "94N" },
    { fieldName: "payrollFileNumber", exampleValue: "002344" },
    {
      fieldName: "payrollScheduleGroupID",
      exampleValue: "Append 1 to period end date ",
    },
    {
      fieldName: "managementPositionIndicator",
      exampleValue: "False",
    },
    {
      fieldName: "baseReunumeration.effectiveDate",
      exampleValue: "2024-07-12",
    },
  ],
];

export const API_TITLE = [
  "Personally Identifiable Information (PII)",
  "Demographic Information",
  "Employment Information",
  "Compensation Information",
];

export const INTEGRATION_OPTIONS = [
  {
    label: "Build integrations with my company's ADP data",
    value: "0",
  },
  {
    label: "Sell my integrated solution to ADP clients",
    value: "1",
  },
];

export const integrationFoundation = [
  {
    title: "Product guides",
    desc: "Step-by-step implementation guides to help you build integrations to a specific ADP product",
    url: "/guides/api-guides",
  },
  {
    title: "Marketplace guides",
    url: "/guides/adp-marketplace-integration-guides",
    desc: "Learn how to setup your listing on our digital storefront and how ADP authentication and authorization models work",
  },
  {
    title: "Key Concepts",
    desc: "Learn about authentication, single-sign on and our digital storefront platform",
    url: "/getting-started/key-concepts",
  },
];

export const USE_CASE_ROLES = [
  { label: "ADP clients", value: "ADP clients" },
  {
    label: "ADP Marketplace partners",
    value: "ADP Marketplace partners",
  },
];

export const TIMELINE_1_ITEMS = [
  {
    header: "Purchase",
    message: "Purchase ADP API Central for your company.",
    illustrationName: "shopping-basket",
    details: "",
  },
  {
    header: "Sign in",
    message:
      "Use your existing ADP credentials to sign in to the API Central application.",
    illustrationName: "browser-locked",
    details: "",
  },
  {
    header: "Onboard",
    message: "Add internal and external team members and assign user roles.",
    illustrationName: "gears",
    details: "",
  },
  {
    header: "Create project",
    message:
      "Start with prepackaged API use cases and add more APIs as you need them.",
    illustrationName: "clipboard-gear",
    details: "",
  },
  {
    header: "Build",
    message:
      "Develop your API integration using in-house talent or a third-party integrator.",
    illustrationName: "laptop-code",
    details: "",
  },
  {
    header: "Launch",
    message: "Push your code live into a production environment.",
    illustrationName: "high-five",
    details: "",
  },
];

export const TIMELINE_2_ITEMS = [
  {
    header: "Engagement",
    illustrationName: "paperless",
    message: [
      "Application submission",
      "Application review and approval based on ADP criteria",
      "Security and legal review",
      "Sandbox agreement",
    ],
    details:
      "After you submit your application, the ADP Marketplace team will review it and contact you if your solution may be a good fit.\n Given the volume of applications we receive, please allow time for our team to review and reply.\n If you are approved to move forward after an initial conversation with our team about your product, business model, and vision for your integration, you will complete security and legal reviews, then sign a sandbox agreement.",
  },
  {
    header: "Project planning",
    illustrationName: "clipboard-gear",
    message: [
      "Sandbox access",
      "Pricing structure",
      "Provide milestones",
      "Project plan creation",
      "Execution of developer agreement",
      "Kickoff call",
    ],
    details: `After you sign the sandbox agreement, you can begin scoping your project. During this phase, you’ll provide the ADP team with milestones and your project plan. You’ll also work with your business development team to determine your solution’s pricing structure\n Then, your team will sign our developer participation agreement, which outlines the terms of the partnership, and have a kickoff call to meet the broader ADP Marketplace team.`,
  },
  {
    header: "Integrations",
    illustrationName: "laptop-code",
    message: [
      "Build three integrations:",
      "Data integration",
      "ADP Marketplace storefront (e‑commerce)",
      "Single sign‑on (SSO)",
    ],
    details:
      "Each ADP Marketplace partner is required to build three separate integrations: one to share data between your solution and ADP, one to enable e‑commerce purchasing through ADP Marketplace, and one to enable single sign-on with ADP credentials\n As you work on your integrations, your marketing team will write your app listing marketing content, which will help introduce ADP clients and sales teams to your solution.",
  },
  {
    header: "Certifications",
    illustrationName: "shield",
    message: [
      "Demo development projects",
      "App security certification and remediation",
    ],
    details:
      "After you build your integrations, you’ll demo your project to your ADP Marketplace technical advisor to show you’ve completed your technical requirements, including your API use cases, order subscription processing, single sign-on, and more\n Your solution will also complete mandatory data security and privacy review by ADP’s Global Security Organization (GSO). The review, testing and issue resolution process helps assure ADP and our clients that data is processed, governed, and transmitted securely.",
  },
  {
    header: "Go live",
    illustrationName: "high-five",
    message: [
      "Production project and app creation",
      "Pricing approval",
      "Final demo to ADP",
      "App listing approval",
      "App publishing",
    ],
    details:
      "Once you’ve passed security review, you’ll demo your project to your technical advisor again in a production environment to ensure your integrations are working as expected\n After you’ve configured your product pricing, your complete purchase flow works, and the ADP Marketplace team has approved your app listing content, you’ll be ready to go live and start selling to ADP clients.",
  },
];

export const FAQ_ITEMS = [
  {
    header:
      "What is the difference between an ADP client and an ADP Marketplace partner?",
    detail: `
    ADP Clients can sign a Marketplace global contract for ADP® API Central and buy a subscription access to the API library in order to build their own integrations with internal or external software (leveraging their in-house IT teams or a 3rd party integrator).  Upon contract signature, the client is responsible to develop the integration and lead the project. The Marketplace Operations Team will provide guidance and support.
    <br />
    <br />
    ADP Marketplace partners are third-party companies that offer integrated HR solutions to ADP clients. A Marketplace Partner is an enterprise that has signed a partnership contract with ADP to offer their own IT solutions to cope with clients needs for multiple HR use cases or they offer their integration development services using ADP APIs acting as our trusted partners.`,
  },
  {
    header: "What is the difference between United States and International?",
    detail: `
    Whilst the Marketplace concept is the same whether we talk about United States or International, there are some differences like: the  ADP Products that can be integrated depending on the market, the onboarded Partners that offer integrated solutions and consulting services, the available APIs which will vary by ADP product and some of the processes as well. There are also two dedicated Marketplace Operations Teams which offer global coverage for our clients. We also count on different app stores where Clients can find the solutions of their choice depending on their location and need.`,
  },
  {
    header: "What do I need to get started calling ADP APIs?",
    detail: `            Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque elit
    tincidunt turpis fames condimentum; molestie metus cursus a. maximus
    senectus aliquet, vestibulum rutrum fames habitasse mattis. Aliquam
    fames ex faucibus rutrum egestas aliquam dolor elementum. Ut
    sollicitudin dis montes dictum faucibus duis. Congue cubilia augue
    tincidunt sociosqu euismod. Massa nisi orci dignissim massa nullam
    potenti natoque`,
  },
  {
    header: "What type of data is accessible through ADP APIs?",
    detail: `After you’re accepted into the ADP Marketplace partner program, you’ll determine milestones and create a project plan to build your integrations. Your timeline will largely depend on your team and resources. Many highly engaged and motivated partners have completed their projects in three to six months, excluding security review or other activities required to go live, but this varies by partner. `,
  },
  {
    header:
      "Where can I learn more about how to purchase API Central as an ADP client?",
    detail: `Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque elit
    tincidunt turpis fames condimentum; molestie metus cursus a. maximus
    senectus aliquet, vestibulum rutrum fames habitasse mattis. Aliquam
    fames ex faucibus rutrum egestas aliquam dolor elementum. Ut
    sollicitudin dis montes dictum faucibus duis. Congue cubilia augue
    tincidunt sociosqu euismod. Massa nisi orci dignissim massa nullam
    potenti natoque`,
  },
];
