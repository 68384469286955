import React from 'react';
import { SdfBusyIndicator } from "@synerg/react-components";
import ArticleBrief from "./ArticleBrief";
import { articlesApiTransform } from "../../utility/transform/articlesApi";

function ArticlesList({ articlesFetch, navigatePath, excludeDate }) {
    if (articlesFetch.error) {
        return <h3>Error loading Products</h3>
    }
    if (articlesFetch.isLoading) {
        return <SdfBusyIndicator />
    }
    return articlesFetch.data?.articles.map((article, index) =>
        <ArticleBrief article={articlesApiTransform(article, excludeDate)} key={index} navigatePath={navigatePath} />
    )
}

export { ArticlesList }