const mkplIntegrationCategories = [
  { label: "Partner Onboarding", value: "Partner-Onboarding" },
  {
    label: "Authentication and Security",
    value: "Authentication-and-Security",
  },
  { label: "Storefront Integration", value: "Storefront-Integration" },
  { label: "Single Sign-On", value: "Single-Sign-On" },
  { label: "Marketing and Sales", value: "Marketing-and-Sales" },
  { label: "International", value: "International" },
];

const productIntegrationcategories = [
    {
        group: 'North America',
        items: [
            { label: 'Recruiting', value: 'Recruiting' },
            { label: 'Human Resources', value: 'Human-Resources' },
            { label: 'Time & Labor Management', value: 'Time-Labor-Management' },
            { label: 'Payroll Management', value: 'Payroll-Management' },
            { label: 'Benefit Management', value: 'Benefit-Management' },
            { label: 'Compliance Solutions', value: 'Compliance-Solutions' },
        ]
    },
    {
        group: 'International',
        items: [
            { label: 'Time & Activities Management', value: 'Time & Activities Management' },
            { label: 'Productivity & Collaboration', value: 'Productivity & Collaboration' },
            { label: 'Travel & Expense Management', value: 'Travel & Expense Management' },
            { label: 'Recruitment & Onboarding', value: 'Recruitment & Onboarding' },
            { label: 'Engagement & Benefits', value: 'Engagement & Benefits' },
            { label: 'Learning Management', value: 'Learning Management' },
            { label: 'Compensation', value: 'Compensation' },
            { label: 'Finance', value: 'Finance' },
            { label: 'External HR & Data Connectors', value: 'External HR & Data Connectors' },
            { label: 'Performance', value: 'Performance' },
        ]
    }

];

const adpNorthAmericaProduct = [
  {
    label: "ADP Workforce Now®",
    value: "ADP Workforce Now®",
    id: "hcm-offrg-wfn",
  },
  {
    label: "ADP® Workforce Manager",
    value: "ADP® Workforce Manager",
  },
  {
    label: "RUN Powered by ADP®",
    value: "RUN Powered by ADP®",
    id: "pr-offrg-run",
  },
  {
    label: "ADP Vantage HCM®",
    value: "ADP Vantage HCM®",
    id: "hcm-offrg-vantg",
  },
  {
    label: "ADP Enterprise HR®",
    value: "ADP Enterprise HR®",
    id: "hcm-offrg-ent",
  },
  {
    label: "ADP Lyric",
    value: "ADP Lyric",
    id: "hcm-offrg-lifion",
  },
  {
    label: "ADP® Recruiting Management",
    value: "ADP® Recruiting Management",
    id: "tal-offrg-tal.rcrtg",
  },
  {
    label: "ADP SmartCompliance® Tax Credits",
    value: "ADP SmartCompliance® Tax Credits",
    id: "tax-offrg-tcs",
  },
  {
    label: "ADP® DataCloud Embedded Direct Deposit Enablement",
    value: "ADP® DataCloud Embedded Direct Deposit Enablement",
  },
  {
    label: "ADP® DataCloud Employment and Income Verification",
    value: "ADP® DataCloud Employment and Income Verification",
    id: "anlyt-offrg-data.cld",
  },
  {
    label: "ADP Workforce Now® Next Generation",
    value: "ADP Workforce Now® Next Generation",
    id: "hcm-offrg-wfn.next.gen",
  },
  {
    label: "ADP® Next Gen Payroll",
    value: "ADP® Next Gen Payroll",
    id: "pr-offrg-pi",
  },
  {
    label: "ADP SmartCompliance®",
    value: "ADP SmartCompliance®",
    id: "cp-offrg-smart.cp",
  },
  {
    label: "ADP Screening and Selection Services®",
    value: "ADP Screening and Selection Services®",
    id: "tal-offrg-sass",
  },
  {
    label: "ADP Insurance Services",
    value: "ADP Insurance Services",
    id: "ins-offrg-ins.svcs",
  },
  {
    label: "ADP® Embedded Payroll",
    value: "ADP® Embedded Payroll",
    id: "pr-offrg-adp.embdd.pr",
  },
  {
    label: "ADP Workforce Now On the Go®",
    value: "ADP Workforce Now On the Go®",
    id: "hcm-offrg-wfn.otg",
  },
];

const adpIternationalProduct = [
  {
    label: "ADP® Link",
    value: "ADP® Link",
    id: "pr-offrg-adp.link.prem",
  },
  {
    label: "ADP Decidium®",
    value: "ADP Decidium®",
    id: "hcm-offrg-dge",
  },
  {
    label: "ADP® iHCM",
    value: "ADP® iHCM",
    id: "hcm-offrg-ihcm.2",
  },
  {
    label: "ADP Workforce®",
    value: "ADP Workforce®",
    id: "hcm-offrg-wkfc",
  },
  {
    label: "ADP eXpert®",
    value: "ADP eXpert®",
  },
  {
    label: "ADP GlobalView®",
    value: "ADP GlobalView®",
  },
  {
    label: "ADP Celergo®",
    value: "ADP Celergo®",
  },
  {
    label: "ADP Time Connect",
    value: "ADP Time Connect",
    id: "ta-offrg-gta",
  },
  {
    label: "ADP eService-Case Management",
    value: "ADP eService-Case Management",
    id: "crm-int-siebel.crm-g.fr-o.oracle",
  },
  {
    label: "ADP® AdvancedPay",
    value: "ADP® AdvancedPay",
    id: "pr-offrg-paisy.advd",
  },
  {
    label: "ADP® OneService",
    value: "ADP® OneService",
    id: "hcm-offrg-sipert",
  },
];

const findFromProductsAndId = (products, productId) =>
  products.find((product) => {
    if (!productId) return;
    return product.id && product.id === productId;
  });
const findProductFromId = (productId) =>
  findFromProductsAndId(adpNorthAmericaProduct, productId) ||
  findFromProductsAndId(adpIternationalProduct, productId);

function getBreadcrumb(pageName, articleTitle, articleLink) {
  let ret;
  switch (pageName) {
    case "key-concepts":
      ret = [
        {
          name: "Home",
          route: "/",
        },
        {
          name: "Getting Started / Key Concepts",
          route: "/getting-started/key-concepts",
        },
        { name: articleTitle },
      ];
      break;
    case "how-to-articles":
      ret = [
        {
          name: "Home",
          route: "/",
        },
        {
          name: "How-to Articles",
          route: "/getting-started/how-to-articles",
        },
        { name: articleTitle },
      ];
      break;
    case "adp-marketplace-integration-guides":
      ret = [
        {
          name: "Home",
          route: "/",
        },
        {
          name: "Guides",
          route: "",
        },
        {
          name: "ADP Marketplace Integration Guides",
          route: "/guides/adp-marketplace-integration-guides",
        },
      ];
      if (articleLink) {
        ret.push(
          {
            name: articleLink[1],
            route: `/guides/adp-marketplace-integration-guides/${articleLink[0]}`,
          },
          { name: articleTitle }
        );
      } else {
        ret.push({
          name: articleTitle,
        });
      }
      break;
    case "api-guides":
      ret = [
        {
          name: "Home",
          route: "/",
        },
        {
            name: "Guides"
          },
        {
          name: "API Guides",
          route: "/guides/api-guides",
        },
      ];
      if (articleLink) {

        ret.push(
          {
            name: articleLink[1],
            route: `/guides/api-guides/${articleLink[0]}`,
          },
          { name: articleTitle }
        );
      } else {
        ret.push({
          name: articleTitle,
        });
      }
      break;
    default:
      ret = [];
  }
  return ret;
}

const isFetching = (query) => query.isLoading || query.status === "pending";

export {
  mkplIntegrationCategories,
  productIntegrationcategories,
  adpNorthAmericaProduct,
  adpIternationalProduct,
  getBreadcrumb,
  findProductFromId,
  isFetching,
};
