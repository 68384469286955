export const FAQ_ITEMS = [
  {
    header:
      "Where can I get more guidance on using ADP APIs and the best way to integrate with our ADP system?",
    detail: `The ADP Professional Services team can recommend integration options, including using ADP APIs and ADP API Central. Complete our <a target="_blank" href="https://forms.office.com/pages/responsepage.aspx?id=gIQsTPDTW0i3UIB_9pOAL7-lT9WD1B9DnG_-de4DYDxUMEk3VTVBS1Y1RVpCN0IyNkRJM0FJWU9BSS4u">Integration Needs Discovery Form</a>, and a team member will contact you to schedule a free consultation and recommendation.`,
  },
  {
    header: `Which ADP systems are supported by ADP® API Central?`,
    detail: `
    ADP API Central is an add-on subscription available for the following ADP systems: <br />
    <br />

    &nbsp;&nbsp; &#8226; &nbsp; ADP Workforce Now® <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; ADP Workforce Now® Next Generation <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; ADP Vantage HCM® <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; ADP Enterprise HR® (V6) <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; ADP Lyric <br />
    <br />
    Available APIs vary by platform; you can explore all APIs here: <br /> <a target="_blank" href="${process.env.PUBLIC_URL}/apis/api-explorer">https://developers.adp.com/apis/api-explorer.</a>
    `,
  },
  {
    header: `How much does ADP® API Central cost?`,
    detail: `
    ADP® API Central is priced per employee per month. To request a price quote or purchase online, visit the app listings on ADP Marketplace: <br />
    <br />
    &nbsp;&nbsp; &#8226; &nbsp; <a target="_blank" href="https://apps.adp.com/en-US/apps/410612">ADP® API Central for ADP Workforce Now® </a><br />
    &nbsp;&nbsp; &#8226; &nbsp; <a target="_blank" href="https://apps.adp.com/en-US/apps/419897">ADP® API Central for ADP Vantage HCM®, ADP Enterprise HR® and ADP Lyric</a>
    `,
  },
  {
    header: `How do ADP® API Central clients get support for the product?`,
    detail: `
    There are multiple ways API Central clients can get integration support: <br />

    <br />
    <b>ADP API Central ticketed support:</b> You can file support tickets from within the API Central portal and our team will respond promptly to help keep your project on track. <br />
    <br />
    <b>ADP API Central consulting support:</b> ADP API specialists are available for an additional fee to guide you through your development efforts including API selection and best practices to drive your project to completion quickly. <br />
    `,
  },
  {
    header: "Can ADP build integrations for my organization after we purchase ADP API Central?",
    detail: `
    ADP does not offer integration development services directly, however, experienced ADP Marketplace partners are available to build and manage your custom integrations. Our team can recommend partners if you complete our <a target="_blank" href="https://forms.office.com/pages/responsepage.aspx?id=gIQsTPDTW0i3UIB_9pOAL7-lT9WD1B9DnG_-de4DYDxUMEk3VTVBS1Y1RVpCN0IyNkRJM0FJWU9BSS4u">Integration Needs Discovery Form.</a>
    `,
  },
];