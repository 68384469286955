import { createSearchParams } from "react-router-dom";

export function navigateToSwaggerPath(navigate, method, pathname) {
  return navigate(
    {
      search: `?${createSearchParams({ operation: method + pathname })}`,
      hash: "#swagger",
    },

    { replace: true }
  );
}
