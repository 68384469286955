import Header from "./Header";
import ApiLibrary from "./ApiLibrary";
import BuildingIntegrations from "./BuildingIntegrations";
import GetStarted from "./GetStarted";
import ExpertConsulting from "./ExpertConsulting";
import RecommendApiCentral from "./RecommendApiCentral";
import AvailableGuides from "./AvailableGuides";
import BuyNow from "./BuyNow";
import { guideItemList } from "./guideItemList";
import { FAQs } from "../shared/FAQs";
import { FAQ_ITEMS } from "./faqItemList";
import Breadcrumb from "../Breadcrumb";
import PageLoading from "../shared/PageLoading";

const ClientIntegration = () => {
  const breadcrumbPath = [
    { name: "Home", route: "/" },
    {
      name: "Getting Started / Client Integration Overview",
      route: "/getting-started/client-integration-overview",
    },
  ];

  return (
    <PageLoading>
      <div className="w-4/5 flex justify-start pl-10">
        <Breadcrumb path={breadcrumbPath} />
      </div>
      <BuyNow />
      <div className="overflow-hidden flex flex-col items-center gap-12 py-24">
        <Header />
        <ApiLibrary />
        <BuildingIntegrations />
        <GetStarted />
        <ExpertConsulting />
        <RecommendApiCentral />
        <AvailableGuides guideItemList={guideItemList} />
        <div className="min-w-full bg-action-default-50 relative">
          <FAQs faqItems={FAQ_ITEMS} />
        </div>
      </div>
    </PageLoading>
  );
};

export default ClientIntegration;
