import { useState, useEffect } from "react";
import {
  SdfPromoBox,
  SdfButton,
  SdfIcon,
  SdfCarousel,
  SdfCarouselItem,
} from "@synerg/react-components";

import "./Timeline.css";

/**
 * @typedef {{
 *  header: string,
 *  message: string,
 *  illustrationName: string,
 *  details: string
 * }} TimelineItem
 */

/**
 *
 * @param {TimelineItem & {itemIndex: number, itemsPerPage: number}} props
 */
const TimelineCarouselItem = ({
  itemIndex,
  itemsPerPage,
  details,
  ...rest
}) => (
  <div
    className={`h-lg ${
      itemsPerPage === 1
        ? "px-16"
        : `${itemIndex % 2 === 1 ? "pl-8 pr-16" : "pl-16 pr-8"}`
    }`}
  >
    <SdfPromoBox variant="clear" align="center" size="sm" {...rest} />
    <div className="relative m-0 text-body-02 whitespace-pre-wrap">
      <hr className="absolute left-0 right-0 m-0 timeline-carousel-item-hr" />
      {details}
    </div>
  </div>
);

/**
 *
 * @param {{
 *  timelineItems: Array<TimelineItem>,
 *  itemsPerPage: number
 * }} props
 */
const TimelineCarousel = ({ timelineItems, itemsPerPage }) => (
  <SdfCarousel
    className="h-xl"
    {...{ itemsPerPage }}
    onSdfChange={(res) => console.log(res)}
  >
    {timelineItems.map((item, itemIndex) => (
      <SdfCarouselItem
        key={`timeline-carousel-${itemIndex}`}
        id={`timeline-carousel-${itemIndex}`}
      >
        <TimelineCarouselItem {...item} {...{ itemIndex, itemsPerPage }} />
      </SdfCarouselItem>
    ))}
  </SdfCarousel>
);

/**
 * @param {TimelineItem & { onClick?: () => void, focus: boolean, index: number, cursor: boolean }} props
 */
const TimeLineItem = ({ focus, index, cursor, ...rest }) => (
  <SdfPromoBox
    className={`pt-4 row-span-2 lg:row-span-1 lg:col-span-2 timeline-item${
      focus ? " timeline-focus" : ""}${cursor ? " cursor" : ""}`}
    style={{ "--timeline-item-index": index }}
    variant="clear"
    align="center"
    size="sm"
    {...rest}
  />
);

const HomePageTimeline = ({ focus, index, message, ...rest }) => {
  const data = { ...message };
  const tasks = Object.values(data);
  const formattedMessage = tasks.map((task) => `\u2022 ${task} \n`);

  return (
    <SdfPromoBox
      className={`pt-4 row-span-2 lg:row-span-1 lg:col-span-2 timeline-item${
        focus ? " timeline-focus" : ""
      }`}
      style={{ "--timeline-item-index": index }}
      variant="clear"
      align="left"
      size="sm"
      message={formattedMessage}
      {...rest}
    />
  );
};

/**
 * @param {{
 *  timelineItems: Array<TimelineItem>,
 *  hideDetails?: boolean,
 *  timelineRef?: React.RefObject<HTMLDivElement>
 * }} props
 */
const Timeline = ({
  timelineItems,
  hideDetails,
  timelineRef,
  homePageTimeline,
  cursor,
}) => {
  const [index, setIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { header, details } = timelineItems[index] || {};

  const prevButtonProps = {
    className: "no-underline whitespace-nowrap",
    emphasis: "primary",
    variant: "text",
    onClick: () => {
      setIndex(
        (lastIndex) =>
          (lastIndex - 1 + timelineItems.length) % timelineItems.length
      );
    },
  };

  const nextButtonProps = {
    className: "",
    emphasis: "primary",
    variant: "text",
    onClick: () => {
      setIndex((lastIndex) => (lastIndex + 1) % timelineItems.length);
    },
  };

  if (index === 0) {
    prevButtonProps.disabled = true;
  } else if (index === timelineItems.length - 1) {
    nextButtonProps.disabled = true;
  }

  if (windowWidth < 1024 && !hideDetails) {
    const carouselProps = {
      timelineItems,
      itemsPerPage: windowWidth < 768 ? 1 : 2,
    };

    return (
      <div
        className="p-4 bg-default border-solid border-1 rounded-lg"
        ref={timelineRef}
      >
        <TimelineCarousel {...carouselProps} />
      </div>
    );
  }

  const TimeLineItemComponent = homePageTimeline
    ? HomePageTimeline
    : TimeLineItem;

  return (
    <div
      className={`w-full bg-default overflow-hidden flex flex-col xl:flex-row ${
        hideDetails
          ? ""
          : " border-solid border-1 rounded-lg timeline-container"
      }`}
    >
      <div className="flex-grow p-4" ref={timelineRef}>
        <div
          className="relative grid grid-flow-row-dense grid-cols-2 auto-rows-fr lg:grid-flow-col-dense lg:grid-rows-2 lg:auto-cols-fr gap-4 timeline-grid"
          style={{ "--timeline-items": timelineItems.length }}
        >
          {timelineItems.map((item, itemIndex) => (
            <TimeLineItemComponent
              key={`timeline-item-${itemIndex}`}
              index={itemIndex}
              focus={!hideDetails && itemIndex === index}
              onClick={() => setIndex(itemIndex)}
              cursor={cursor}
              {...item}
            />
          ))}
        </div>
      </div>
      {!hideDetails && (
        <div className="flex-1 bg-alert-neutral-200 min-w-60 p-4">
          <div className="w-3/4 md:w-1/2 xl:w-full h-full m-auto flex flex-col gap-4">
            <div className="m-0 text-heading-05">{header}</div>
            <div className="m-0 text-body-02 flex-grow whitespace-pre-wrap">
              {details}
            </div>
            <div className="flex gap-2">
              <SdfButton {...prevButtonProps}>
                <SdfIcon icon="nav-move-previous" />
                <span> Prev</span>
              </SdfButton>
              <div className="flex-grow" />
              <SdfButton {...nextButtonProps}>
                <span>Next </span>
                <SdfIcon icon="nav-move-next" />
              </SdfButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Timeline;
